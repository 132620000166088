module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kuda. - The money App for Africans.","short_name":"Kuda.","start_url":"/","background_color":"#40196d","theme_color":"#40196d","display":"minimal-ui","icon":"./static/favicon/kuda-app-icon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"817086b560dfc1021d60b8ab9b66d485"},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"Ws4QQixp3PWqBe8rP8nI5lMotiIbo6Yq","devKey":"tSofb04EhLeJG6LGX8C4EHueaqIg0uCt"},
    },{
      plugin: require('../node_modules/gatsby-plugin-datadog/gatsby-browser.js'),
      options: {"plugins":[],"site":"datadoghq.com","sampleRate":10,"enabled":true,"service":"Kuda Global Website","forwardErrorsToLogs":true,"env":"Test","rum":{"clientToken":"pub775793cee1042ab716aa86147a3d81cf","applicationId":"20664163-7344-4e2b-a85d-59c95ee22753"},"logs":{"clientToken":"pub775793cee1042ab716aa86147a3d81cf"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
